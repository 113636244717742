import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

import TwitterIcon from "../assets/icons/twitter.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          twitter
        }
      }
    }
  `)
  return (
    <footer className="py-8 border-t-2 border-orange-700 bg-orange-200 text-orange-900">
      <div className="container flex items-center justify-between">
        <div>
          © {new Date().getFullYear()} <Link to="/">jocsdetaula.club</Link>
        </div>
        <ul class="-mx-2">
          {data.site.siteMetadata.twitter && (
            <li class="px-2">
              <a
                href={data.site.siteMetadata.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-orange-700"
                aria-label="Twitter"
              >
                <TwitterIcon className="w-6 h-6 fill-current" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
