import React from "react"
import { Link } from "gatsby"

import SiteLogo from "../assets/icons/logo.svg"

const Header = ({ className }) => (
  <header className={`absolute w-full py-8 z-30 ${className}`}>
    <div className="container">
      <Link to="/">
        <SiteLogo className="fill-current" />
      </Link>
    </div>
  </header>
)

export default Header
